import React from 'react'

const MadeWithIcon = ({ width, style, fill }) => {
  return (
    <svg
      data-name="Layer 1"
      id="Layer_1"
      viewBox="0 0 32 32"
      width={width}
      height={width}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon fill="#740e37" points="16 2 4 8 4 24 16 30 28 24 28 8 16 2" />
      <polygon
        fill="#740e37"
        points="16 2 15 2.5 26 8 26 24 15 29.5 16 30 28 24 28 8 16 2"
      />
      <polygon
        fill="#740e37"
        points="16 2 17 2.5 6 8 6 24 17 29.5 16 30 4 24 4 8 16 2"
      />
      <path
        fill="#fff"
        d="M16,31a1,1,0,0,1-.447-.105l-12-6A1,1,0,0,1,3,24V8a1,1,0,0,1,.553-.895l12-6a1,1,0,0,1,.894,0l12,6A1,1,0,0,1,29,8V24a1,1,0,0,1-.553.895l-12,6A1,1,0,0,1,16,31ZM5,23.382l11,5.5,11-5.5V8.618l-11-5.5L5,8.618Z"
      />
      <path
        fill="#fff"
        d="M24.243,16.03l-5.378-1.344L24.447,11.9a1,1,0,0,0,0-1.79C20.826,8.451,20.3,7.641,19.474,8.149c-.8.5-.346,1.356-.474,4.233-2.874,1.609-3.992,1.567-4,2.61C14.692,9.5,15.74,7,14,7a1,1,0,0,0-1,1v9.279c-2.335.666-2.886,1.261-3.584.758C8.782,17.58,9.073,16.888,9,16a1,1,0,0,0-2,0c.095.645-.421,2.458,1.246,3.659,1.788,1.289,3.4.019,4.742-.267-.062,2.663-.06,4.638,2.7,5.557A1,1,0,0,0,16,25c1.37-.116,2.5.349,2.915-.6.4-.91-.628-1.431-1.172-2.169-.545-1.493,1.849-1.605,6.7-4.34a1,1,0,0,0-.2-1.865ZM21,10.618l.764.382L21,11.382Zm-3.658,8.593a3,3,0,0,0-1.5,3.642c-1.02-.542-.874-.908-.84-7.846.019,1.341,1.616.957,6.135,2.307Z"
      />
    </svg>
  )
}

export default MadeWithIcon
