const articleSchema = (
  url,
  title,
  image,
  metaDate,
  metaUpdateDate,
  metaDescription,
  metaCategory
) => {
  const schema = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': 'https://historiaislamica.com.br/#organization',
        name: 'História Islâmica',
        url: 'https://historiaislamica.com.br/',
        sameAs: [
          'https://www.facebook.com/historiaislamica',
          'https://www.instagram.com/historiaislamicaoficial',
        ],
        logo: {
          '@type': 'ImageObject',
          '@id': 'https://historiaislamica.com.br/#logo',
          inLanguage: 'pt-BR',
          url: 'https://historiaislamica.com.br/logo.png',
          width: 570,
          height: 180,
          caption: 'História Islâmica',
        },
        image: {
          '@id': 'https://historiaislamica.com.br/#logo',
        },
      },
      {
        '@type': 'WebSite',
        '@id': 'https://historiaislamica.com.br/#website',
        url: 'https://historiaislamica.com.br/',
        name: 'História Islâmica',
        inLanguage: 'pt-BR',
        description: 'História Islâmica, do Profeta Muhammad ao dias atuais',
        publisher: {
          '@id': 'https://historiaislamica.com.br/#organization',
        },
        potentialAction: {
          '@type': 'SearchAction',
          target:
            'https://historiaislamica.com.br/search?s={search_term_string}',
          'query-input': 'required name=search_term_string',
        },
      },
      {
        '@type': 'ImageObject',
        '@id': `${url}/#primaryimage`,
        inLanguage: 'pt-BR',
        url: image,
        width: 1200,
        height: 628,
        caption: title,
      },
      {
        '@type': 'WebPage',
        '@id': `${url}/#webpage`,
        url: url,
        name: title,
        isPartOf: {
          '@id': 'https://historiaislamica.com.br/#website',
        },
        inLanguage: 'pt-BR',
        primaryImageOfPage: {
          '@id': `${url}/#primaryimage`,
        },
        datePublished: metaDate,
        dateModified: metaUpdateDate,
        description: metaDescription,
      },
      {
        '@type': 'Article',
        '@id': `${url}/#article`,
        isPartOf: {
          '@id': `${url}/#webpage`,
        },
        author: {
          '@id':
            'https://historiaislamica.com.br/#/schema/person/13bd408ff759a4d0c4cad95ac0b4ed06',
        },
        headline: title,
        datePublished: metaDate,
        dateModified: metaUpdateDate,
        commentCount: 0,
        mainEntityOfPage: {
          '@id': `${url}/#webpage`,
        },
        publisher: {
          '@id': 'https://historiaislamica.com.br/#organization',
        },
        image: {
          '@id': `${url}/#primaryimage`,
        },
        articleSection: metaCategory,
        inLanguage: 'pt-BR',
      },
      {
        '@type': ['Person'],
        '@id':
          'https://historiaislamica.com.br/#/schema/person/13bd408ff759a4d0c4cad95ac0b4ed06',
        name: 'A Redação',
        description:
          'A Equipe de Redação do História Islâmica é multidisciplinar e composta por profissionais da área de Marketing, Ilustração/Design, História, Administração, Gestão de Recursos Humanos, Tradutores Especializados (Árabe e Inglês) e especialistas na Religião Islâmica. Atualmente a equipe é composta por 10 profissionais.',
        sameAs: [],
      },
    ],
  }

  return schema
}

export default articleSchema
