import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import sanitizeHtml from 'sanitize-html'

import articleSchema from '../schemas/article'

import ptLogo from '../images/logo.png'
import enLogo from '../images/logo-en.png'

function SEO({
  description,
  lang,
  meta,
  title,
  post = false,
  image,
  canonical,
  date,
  dateUpdated,
  category,
  isAlternate,
  alternateUrl,
}) {
  let langDescription =
    lang === 'pt'
      ? 'História Islâmica, do Profeta Muhammad ao dias atuais'
      : "The History of the religion of Islam from the Prophet's time to our days"

  let metaDescription = description || langDescription

  metaDescription = sanitizeHtml(metaDescription.slice(0, 120), {
    allowedTags: [],
    allowedAttributes: {},
  })

  image = image || 'https://historiaislamica.com.br/logo.png'

  let historiaLogo = lang === 'pt' ? ptLogo : enLogo

  const defaultTitle = 'História Islâmica'

  let linkTagsArray = [{ rel: 'canonical', href: canonical }]

  if (isAlternate) {
    linkTagsArray = [
      { rel: 'canonical', href: canonical },
      { rel: 'alternate', hrefLang: 'en', href: alternateUrl },
    ]
  }

  if (post) {
    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        link={linkTagsArray}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:url`,
            content: canonical,
          },
          {
            property: `og:locale`,
            content: 'pt_BR',
          },
          {
            property: `og:site_name`,
            content: 'História Islâmica',
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: image,
          },
          {
            property: `og:image:secure_url`,
            content: image,
          },
          {
            property: `og:image:width`,
            content: 1200,
          },
          {
            property: `og:image:height`,
            content: 800,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: 'História Islâmica' || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <script type="application/ld+json">
          {JSON.stringify(
            articleSchema(
              canonical,
              title,
              image,
              date,
              dateUpdated,
              metaDescription,
              category
            )
          )}
        </script>
      </Helmet>
    )
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={linkTagsArray}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: 'História Islâmica',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image || historiaLogo,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'História Islâmica' || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `pt`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
